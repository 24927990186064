/* global EM */
import React, { Component } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import addDays from 'date-fns/addDays'
import Routes from '../app/Routes';
import _ from 'underscore';
const ExcelJS = require("exceljs");

class LabBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentRecordDetail: { companyId: this.props.company.companyId },
            endDate: new Date(),
            startDate: new Date(),
            company: this.props.companys,
            orders: [],
            products: [],
            companyConfig: null,
            recordsNotFound: '',
            singleUnitProduct: null,
            singleUnitSessionProduct: null,
            locationId: 0
        };
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.createHeaderCell = this.createHeaderCell.bind(this);
        this.createHeaderRow = this.createHeaderRow.bind(this);
       this.initSummaryData();
    }

    componentDidMount() {
        this.getProducts();
        this.getCompanyConfig();
    }

    initSummaryData(){
        this.summary = {
            totalOrders: this.state.orders ? this.state.orders.length :0,
            totalSales: 0,
            totalLabCost: 0,
            startDate: this.state.startDate ? this.state.startDate.toLocaleDateString() : '',
            endDate: this.state.endDate ? this.state.endDate.toLocaleDateString() : ''
        };
    }

    excelCellStyle(){
        return {
            font: { name: 'Arial', size: 10 },
            alignment: { vertical: 'middle', horizontal: 'left' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    }

    excelHeaderStyle(){
       return {
            font: { bold: true, color: { argb: 'FFFFFFFF' } },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' },
                bgColor: { argb: 'FFFFFFFF' }
            },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };
    }

    getCompanyConfig() {
        let self = this;
        EM.companyConfig.loadSingleEntity({ id: this.props.company.companyId }).then(() => {
            self.setState({ companyConfig: EM.companyConfig.get() || {} });
        });
    }

    getProducts() {
        let self = this;
        EM.products.executeGet(null, null, Routes.clientApi.products, { companyId: this.props.company.companyId }, true).then(() => {
            let products = EM.products.get();
            let singleUnitProduct = products.find((p) => p.productId === 2);
            let singleUnitSessionProduct = products.find((p) => p.productId === 1);
            self.setState({ products, singleUnitProduct, singleUnitSessionProduct });
        });
    }

    endDateChange(date) {
        var self = this;
        self.setState({ endDate: date, maxDate: date });
    }
    startDateChange(date) {
        var self = this;
        self.setState({ startDate: date, minDate: date });
    }

    loadData() {
        var self = this;
        var request = {
            startDate: self.state.startDate.toISOString().split('T')[0],
            endDate: self.state.endDate.toISOString().split('T')[0],
            locationId: self.state.locationId
        }
        EM.completedOrders.executeGet(null, request, Routes.clientApi.completedOrders, { companyId: this.props.company.companyId }, false).then((res) => {
            self.summary = Object.assign({}, self.summary, { totalOrders: res.length, startDate: request.startDate, endDate: request.endDate, totalLabCost: 0, totalSales: 0 });
            self.setState({ orders: res, recordsNotFound: res && res.length > 0 ? '' : 'No records found.' })
        });
    }

    export() {
        let self = this;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Sheet 1");
        let rowNumber = 2;
        self.state.orders.map((order, index) => {
            self.addOrderNumberHeading(sheet, order);
            let rows = [];
            let totalUnits = 0;
            let imageCount = 0;
            let imageUnit = 0;
            let singleImageProducts = [...order.printOrderLineItems].filter((p) => p.product.productId === 2);
            let singleSessionProduct = [...order.printOrderLineItems].filter((p) => p.product.productId === 1);
            let hasSheet  = _.find(order.printOrderLineItems,(lineItems)=>{
                return lineItems.product.unit === 1;
            });

            order.printOrderLineItems.map((lineItem) => {
                let product = lineItem.product;
                if (product && product.productId !== 1 && product.productId !== 2) {
                    let unit = (product.unit ? product.unit : 0) * lineItem.quantity;
                    totalUnits += unit;                    
                    rows.push([
                        product.name,
                        lineItem.quantity,
                        unit
                    ]);
                }
            });

            singleImageProducts.map((lineItem, index) => {
                let product = lineItem.product;
                if (product) {
                    let unit = product.unit ? product.unit : 0;
                    if (index+1 > 1 && index+1 <= 20) unit = 0.18;
                    if (index+1 > 20) unit = 0.04;

                    totalUnits += unit;
                    imageUnit += unit;
                    imageCount += lineItem.quantity;                    
                }
            });

            singleSessionProduct.map((lineItem, index) => {
                let product = lineItem.product;
                if (product) {
                    for (let i = 0; i < product.userSessionImageCount; i++) {
                        let unit = (self.state.singleUnitProduct.unit ? self.state.singleUnitProduct.unit : 0);
                        if (i+1 > 1 && i+1 <= 20) unit = 0.18;
                        if (i+1 > 20) unit = 0.04;

                        totalUnits += unit;
                        imageUnit += unit;
                    }
                    imageCount += product.userSessionImageCount;
                }
            });

            rows.push(["Images", imageCount, imageUnit.toFixed(2)]);

            if(hasSheet)
            {
                rows.push(["First Sheet Processing", "", 1]);
                totalUnits += 1;
            }
             
            rows.push(["Total Units", "Total Sales", "Total lab Cost"]);
            rows.push([`${totalUnits.toFixed(2)}`, `$${order.gross.toFixed(2)}`, `$${(totalUnits * (self.state.companyConfig.unitPrice ? self.state.companyConfig.unitPrice : 2)).toFixed(2)}`]);
            // headers
            self.createHeaderRow(sheet, rowNumber);

            rows.forEach((row, index) => {
                self.createContentRow(sheet, rowNumber + index + 1, row);
            });

            rowNumber += rows.length + 3;
            sheet.addRow(['', '', '']);
        });

        let summary = sheet.addRow(['Summary']);         
        let summaryHeader = sheet.addRow(['Total Orders', 'Total Sales', 'Total Lab Cost','Start Date','End Date'],self.excelHeaderStyle());         
        let summaryContent = sheet.addRow([`${self.summary.totalOrders}`, `$${self.summary.totalSales.toFixed(2)}`,`$${self.summary.totalLabCost.toFixed(2)}`,`${self.summary.startDate}`,`${self.summary.endDate}`]); 
  
        
        self.initSummaryStyles(summaryHeader,summaryContent,self);
        summary.getCell(1).style = { font: { bold: true } };
        sheet.getColumn(1).width = 50;
        sheet.getColumn(2).width = 50;
        sheet.getColumn(3).width = 50;

        workbook.xlsx.writeBuffer().then(function (data) {
            self.triggerDownload(data);
        });
    }

    initSummaryStyles(header,content,self){
        header.getCell(1).style = self.excelHeaderStyle();
        header.getCell(2).style = self.excelHeaderStyle();
        header.getCell(3).style = self.excelHeaderStyle();
        header.getCell(4).style = self.excelHeaderStyle();
        header.getCell(5).style = self.excelHeaderStyle();

        content.getCell(1).style = self.excelCellStyle();
        content.getCell(2).style = self.excelCellStyle();
        content.getCell(3).style = self.excelCellStyle();
        content.getCell(4).style = self.excelCellStyle();
        content.getCell(5).style = self.excelCellStyle();
    }

    triggerDownload(data) {
        const arrayBuffer = new Uint8Array(data);
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
    }

    addOrderNumberHeading(sheet, order,text) {
        var row = sheet.addRow(text ? text:["Order " + order.printOrderId]);
        var headCell = row.getCell(1);
        headCell.style = { font: { bold: true } };
    }

    createHeaderCell(sheet, cellAddress, value) {
        var cell = sheet.getCell(cellAddress);
        cell.value = value;
        cell.style = this.excelHeaderStyle();
    }

    createHeaderRow(sheet, rowNumber) {
        this.createHeaderCell(sheet, `A${rowNumber}`, 'Products');
        this.createHeaderCell(sheet, `B${rowNumber}`, 'Quantity');
        this.createHeaderCell(sheet, `C${rowNumber}`, 'Units');
    }

    createContentCell(sheet, cellAddress, value, bold) {
        var cell = sheet.getCell(cellAddress);
        cell.value = value;
        cell.style = this.excelCellStyle();
        if (bold === true)
            cell.style.font.bold = true;
    }

    createContentRow(sheet, rowNumber, row) {
        let bold = (row[0] === "Total Units" || row[0] === "Total Sales" || row[0] === "Total lab Cost");
        this.createContentCell(sheet, `A${rowNumber}`, row[0], bold);
        this.createContentCell(sheet, `B${rowNumber}`, row[1], bold);
        this.createContentCell(sheet, `C${rowNumber}`, row[2], bold);
    }

    locationChange = (event) => {
        var self = this;
        self.setState({ locationId: event.target.value });
    }

    render() {
        let self = this;
        if (EM.allLoaded(EM.products, EM.companyConfig)) {
            this.initSummaryData();
            return (
                <div>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <Label>Start Date</Label>
                                <DatePicker
                                    selected={self.state.startDate}
                                    onChange={self.startDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    minDate={addDays(new Date(), -10000)}
                                    maxDate={self.state.maxDate}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <Label>End Date</Label>
                                <DatePicker
                                    selected={self.state.endDate}
                                    onChange={self.endDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    minDate={self.state.minDate}
                                    maxDate={new Date()}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <Label>Event</Label>
                                <select className="form-control" onChange={self.locationChange}>
                                    <option value="">Please Select</option>
                                    {self.props.locations.map(loc => <option value={loc.locationId}>{loc.name}</option>)}
                                </select>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Button value="Export" onClick={() => { this.loadData(); }} className="btn btn-primary">Apply</Button>
                                <Button hidden={this.state.orders.length === 0} value="Export" onClick={() => { this.export(); }} className="btn btn-primary ml-2">Export</Button>
                            </FormGroup>
                        </div>
                    </div>
                    {
                        (self.state.orders.length === 0) ? (<div>{this.state.recordsNotFound}</div>) :
                            self.state.orders.map((order) => {
                                let totalUnits = 0;
                                let imageCount = 0;
                                let imageUnit = 0;
                                let singleImageProducts = [...order.printOrderLineItems].filter((p) => p.product.productId === 2);
                                let singleSessionProduct = [...order.printOrderLineItems].filter((p) => p.product.productId === 1);
                                let hasSheet  = _.find(order.printOrderLineItems,(lineItems)=>{
                                    return lineItems.product.unit === 1;
                                });
                                return (
                                    <div className="row">
                                        <div className="col-6">
                                            <div style={{ backgroundColor: '#f5f6fa' }}><h5>Order {order.printOrderId}</h5></div>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr style={{ backgroundColor: "#ecf0f1" }}>
                                                        <th>Products</th>
                                                        <th>Quantity</th>
                                                        <th>Units</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        order.printOrderLineItems.map((lineItem) => {
                                                            let product = lineItem.product;
                                                            if (product && product.productId !== 1 && product.productId !== 2) {
                                                                let unit = (product.unit ? product.unit : 0) * lineItem.quantity;
                                                                totalUnits += unit;                                                               
                                                                return (<tr>
                                                                    <td>{product.name}</td>
                                                                    <td>{lineItem.quantity}</td>
                                                                    <td>{unit}</td>
                                                                </tr>)
                                                            }
                                                        })
                                                    }
                                                    <tr>
                                                        {
                                                            singleImageProducts.map((lineItem, index) => {
                                                                let product = lineItem.product;
                                                                if (product) {
                                                                    let unit = product.unit ? product.unit : 0;
                                                                    if (index+1 > 1 && index+1 <= 20) unit = 0.18;
                                                                    if (index+1 > 20) unit = 0.04;                                                                    
                                                                    totalUnits += unit;
                                                                    imageUnit += unit;
                                                                    imageCount += lineItem.quantity;                                                                    
                                                                }
                                                            })
                                                        }
                                                        {
                                                            singleSessionProduct.map((lineItem, index) => {
                                                                let product = lineItem.product;
                                                                if (product) {
                                                                    for (let i = 0; i < product.userSessionImageCount; i++) {
                                                                        let unit = (self.state.singleUnitProduct.unit ? self.state.singleUnitProduct.unit : 0);
                                                                        if (i+1 > 1 && i+1 <= 20) unit = 0.18;
                                                                        if (i+1 > 20) unit = 0.04;

                                                                        totalUnits += unit;
                                                                        imageUnit += unit;
                                                                    }
                                                                    imageCount += product.userSessionImageCount;
                                                                }
                                                            })
                                                        }
                                                        <td>Image</td>
                                                        <td>{imageCount}</td>
                                                        <td>{imageUnit.toFixed(2)}</td>
                                                    </tr>
                                                    {
                                                        (hasSheet?
                                                        <tr>
                                                            <td>First Sheet Processing</td>
                                                            <td></td>
                                                            <td>1</td>
                                                        </tr>:null)
                                                    }
                                                    <tr>
                                                        <td><b>Total Units</b></td>
                                                        <td><b>Total Sales</b></td>
                                                        <td><b>Total lab Cost</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{(totalUnits + (hasSheet ? 1 : 0)).toFixed(2)}</td>
                                                        <td>${order.gross.toFixed(2)}</td>
                                                        <td>
                                                            ${((totalUnits+ (hasSheet ? 1 : 0)) * (self.state.companyConfig.unitPrice ? self.state.companyConfig.unitPrice : 1)).toFixed(2)}
                                                            <div hidden={true}>
                                                            {self.summary.totalSales += order.gross ? order.gross : 0}
                                                            {self.summary.totalLabCost += ((totalUnits + (hasSheet ? 1 : 0)) * (self.state.companyConfig.unitPrice ? self.state.companyConfig.unitPrice : 2))}
                                                        </div>
                                                        </td>                                                        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>)
                            })
                    }
                    {
                    (self.state.orders.length === 0) ? (<div></div>):
                        (<div className="row">
                        <div className="col-6">
                            <div style={{ backgroundColor: '#f5f6fa' }}><h5>Summary</h5></div>
                            <table className="table table-bordered">
                                <thead>
                                    <tr style={{ backgroundColor: "#ecf0f1" }}>
                                        <th>Total Orders</th>
                                        <th>Total Sales</th>
                                        <th>Total Lab Cost</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.summary.totalOrders}</td>
                                        <td>${this.summary.totalSales.toFixed(2)}</td>
                                        <td>${this.summary.totalLabCost.toFixed(2)}</td>
                                        <td>{this.summary.startDate}</td>
                                        <td>{this.summary.endDate}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>)
                    }
                </div>
            )
        }
        return (<div>Loading...</div>);
    }
}
export default LabBilling;