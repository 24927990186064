/* global EM */
import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Routes from "./Routes";
import { withRouter, matchPath } from "react-router";
import { connect } from "react-redux";
import Header from "../components/Header.js";
import Home from "../pages/Home";
import NoOrderUsers from "../pages/NoOrderUsers";
import UserNoOrder from "../pages/UserNoOrder";
import CompletedOrders from "../pages/CompletedOrders";
import UserCompletedOrders from "../pages/UserCompletedOrders";
import TotalInCompleteOrders from "../pages/TotalInCompleteOrders";
import UserInCompleteOrders from "../pages/UserInCompleteOrders";
import FutureEvents from "../pages/FutureEvents";
import Companies from "../pages/Companies";
import EventGroups  from "../pages/EventGroups";
import Locations from "../pages/Locations";
import Intervals from "../pages/Intervals";
import Compaigns from "../pages/Compaigns";
import Statistics from "../pages/Statistics";
import Events from "../pages/Events";
import Users from '../pages/Users';
import Booking from '../pages/Booking';
import UserDetails from "../pages/UserDetails";
import FetchIndicator from "../components/FetchIndicator";
import ErrorIndicator from "../components/ErrorIndicator";
import TemporaryStatusMessage from "../components/TemporaryStatusMessage";
import CompanyConfig from '../pages/CompanyConfig';
import OrderFullfillment from "../pages/OrderFullfillment";
import Presets from "../pages/Presets";
import Export from "../pages/Export";
import Calendar from "../pages/Calendar";
import UserScheduler from "../pages/UserScheduler";
import RescheduleAppointment from "../pages/RescheduleAppointment";
import RescheduleAppointmentBooking from "../pages/RescheduleAppointmentBooking";
import CompleteOrder from "../pages/CompleteOrder";
import Downloads from '../pages/Downloads';
import Sales from "../pages/Sales";
import _ from 'underscore';
import EventPackages from "../pages/EventPackages";
import GroupUsers from "../pages/GroupUsers";
import Products from '../pages/Products';

const HeaderComp = withRouter(Header);
const HomePage = withRouter(Home);
const FutureEventsPage = withRouter(FutureEvents);
const NoOrderUsersPage = withRouter(NoOrderUsers);
const UserNoOrderPage = withRouter(UserNoOrder);
const CompletedOrdersPage = withRouter(CompletedOrders);
const UserCompletedOrdersPage = withRouter(UserCompletedOrders);
const TotalInCompleteOrdersPage = withRouter(TotalInCompleteOrders);
const UserInCompleteOrdersPage = withRouter(UserInCompleteOrders);
const CompaniesPage = withRouter(Companies);
const EventGroupsPage = withRouter(EventGroups);
const GroupUsersPage = withRouter(GroupUsers);

const ProductsPage = withRouter(Products);
const LocationsPage = withRouter(Locations);
const EventsPage = withRouter(Events);
const UsersPage = withRouter(Users);
const UserDetailsPage = withRouter(UserDetails);

const IntervalsPage = withRouter(Intervals);
const CompaignsPage = withRouter(Compaigns);
const StatisticsPage = withRouter(Statistics);
const EventPackagesPage = withRouter(EventPackages);
const OrderFullfillmentPage = withRouter(OrderFullfillment);
const PresetsPage = withRouter(Presets);
const BookingPage = withRouter(Booking);
const CompanyConfigPage = withRouter(CompanyConfig);
const ExportPage = withRouter(Export);
const CalendarPage = withRouter(Calendar);
const UserSchedulerPage = withRouter(UserScheduler);
const RescheduleAppointmentPage = withRouter(RescheduleAppointment);
const RescheduleAppointmentBookingPage = withRouter(RescheduleAppointmentBooking);
const CompleteOrderPage = withRouter(CompleteOrder);
const DownloadsPage = withRouter(Downloads);
const SalesPage = withRouter(Sales);

const withAuthorize = roles => Component => props => {
  let authorized = false;
  _.each(roles, (role) => {
    let account = EM.account.get();
    let roleDetail = _.findWhere(EM.roles, { name: role });
    if (roleDetail && roleDetail.id > 0 && account.roleId === roleDetail.id)
      authorized = authorized || true;
  });
  return authorized ? Component : <div>You are not authorized to view this page!</div>;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowAnonymous: props.allowAnonymous
    };
  }

  render() {
    return (
      <BrowserRouter key="router">
        <div id="page-container" className={"page-container"}>
          <HeaderComp {...this.props} isHelpMode={false} allowAnonymous={this.state.allowAnonymous} />
          <Switch>
            <Route
              exact
              path={Routes.client.home}
              render={() => <HomePage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.noOrderUsers}
              render={() => <NoOrderUsersPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.userNoOrdersUsers}
              render={() => <UserNoOrderPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.totalCompletedOrders}
              render={() => <CompletedOrdersPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.userCompletedOrders}
              render={() => <UserCompletedOrdersPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.incompleteOrders}
              render={() => <TotalInCompleteOrdersPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.userIncompleteOrders}
              render={() => <UserInCompleteOrdersPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.futureEvents}
              render={() => <FutureEventsPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.companies}
              render={withAuthorize([EM.roleNames.SuperAdmin])(<CompaniesPage {...this.props} />)}
            /> :null

            <Route
              exact
              path={Routes.client.eventGroups}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<EventGroupsPage {...this.props} />)}
            /> :null
            <Route
              exact
              path={Routes.client.groupUser}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<GroupUsersPage {...this.props} />)}
            /> :null
             <Route
              exact
              path={Routes.client.products}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<ProductsPage {...this.props} />)}
            /> :null
            <Route
              exact
              path={Routes.client.locations}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<LocationsPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.calendar}
              render={() => <CalendarPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.events}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<EventsPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.users}
              render={() => <UsersPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.intervals}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<IntervalsPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.compaign}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<CompaignsPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.sendGridStats}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<StatisticsPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.eventPackages}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<EventPackagesPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.booking}
              render={() => {
                return (<BookingPage {...this.props} />);
              }}
            />
            <Route
              exact
              path={Routes.client.companyConfig}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<CompanyConfigPage {...this.props} />)}
            />
             <Route
              exact
              path={Routes.client.downloads}
              render={() => <DownloadsPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.userDetails}
              render={() => <UserDetailsPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.printOrders}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<OrderFullfillmentPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.presets}
              render={withAuthorize([EM.roleNames.SuperAdmin])(<PresetsPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.export}
              render={withAuthorize([EM.roleNames.SuperAdmin,EM.roleNames.Admin])(<ExportPage {...this.props} />)}
            />
            <Route
              exact
              path={Routes.client.userSchdeuler}
              render={() => <UserSchedulerPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.rescheduleAppointment}
              render={() => <RescheduleAppointmentPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.rescheduleAppointmentBooking}
              render={() => {
                return (<RescheduleAppointmentBookingPage {...this.props} />);
              }}
            />
            <Route
              exact
              path={Routes.client.completeOrder}
              render={() => <CompleteOrderPage {...this.props} />}
            />
            <Route
              exact
              path={Routes.client.sales}
              render={() => <SalesPage {...this.props} />}
            />
          </Switch>
          <TemporaryStatusMessage ref={EM.getStatusMessageRef(React)} />
          <FetchIndicator items={this.props.fetching} />
          <ErrorIndicator error={this.props.error} />
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    allowAnonymous: ownProps.allowAnonymous,
    account: state.account,
    activities: state.activities,
    complexities: state.complexities,
    complexityLookup: state.complexityLookup,
    departments: state.departments,
    domain: state.domain,
    domainState: state.domainState,
    error: state.error,
    fetching: state.fetching,
    organizations: state.organizations,
    roles: state.roles,
    files: state.files,
    notes: state.notes,
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps, null)(App);
