/* global EM */
import React, { Component } from "react";
import Dates from '../util/Dates';
import _ from "underscore";
import Routes from "../app/Routes";
import PageTitle from '../components/PageTitle';
import PanelModal from '../components/PanelModal';
import SmartImage from '../components/Widgets/SmartImage'
import { Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
class OrderFullfillment extends Component {
    constructor(props) {
        super(props);
        this.rootContainer = 'https://flashopstorage.blob.core.windows.net/images/';
        this.entity = EM.orderFulfillment;
        this.state = {
            showImages:false,
            userImages:"",
            imageName:"",
            parentRecordDetail: { userId: this.props.match.params.userId, companyId: this.props.match.params.companyId },
            orders: [],
            isSuperAdmin:EM.isSuperAdmin(),
        }
        this.handleRequeue = this.handleRequeue.bind(this);
    }
    handleRequeue(printOrderId){   
        var request = {
          orderId:printOrderId     
        }
        EM.printOrders.executePost(request,null,EM.printOrders.entityTypeDef.requeueUrl, {},false);
    }
    componentDidMount() {
        this.setState({companyName:EM.getDefaultCompany().companyName})    
        let self = this;
        
        let company = EM.getDefaultCompany();
        
        var params ={companyId :company ?company.companyId:0 };
        self.entity.executeGet(null, null, Routes.clientApi.openOrders, params,true).then((res) => {
            this.setState({ orders: self.entity.get() });
        });
    }
    renderAddress(shippingAddress) {
     
        if(shippingAddress ===null) return "";
        let address = "";
        if (!_.isNull(shippingAddress.address1)) {
            address = shippingAddress.address1;
        }
        if (!_.isNull(shippingAddress.address2)) {
            address += ` ${shippingAddress.address2}`;
        }
        if (!_.isNull(shippingAddress.city)) {
            address += ` ${shippingAddress.city}`;
        }
        if (!_.isNull(shippingAddress.state)) {
            address += ` ${shippingAddress.state}`;
        }
        if (!_.isNull(shippingAddress.zip)) {
            address += ` ${shippingAddress.zip}`;
        }
        return address;
    }
    renderImage(userSessionImage,imageName) {
        if(userSessionImage)
        return <a href="#" onClick={()=>this.openImage(userSessionImage.editImageUrl,imageName)}>Image</a>;
        return "";
    }
    fulfilled(order) {
        var self = this;
        const updatedOrder = JSON.parse(JSON.stringify(order));
        updatedOrder.fullfilled = true;
        self.entity.update(order, updatedOrder).then((res) => {
            let data = self.entity.get();
            let printOrders = _.filter([...data], (order) => {
                return order.fullfilled === false;
            });
            self.setState({ orders: printOrders });
        });
    }
    renderOrderLineItems(orderLineItem,index) {
        return (
            <div key={index} className="row ml-1 mr-1 p-2 border-top" style={{ backgroundColor: "#ced6e0" }}>
                <div className="col">{orderLineItem.product.name}</div>
                <div className="col">{orderLineItem.quantity}</div>
                <div className="col">{this.renderImage(orderLineItem.userSessionImage,orderLineItem.product.name)}</div>
            </div>
        )
    }
    handleViewImage() {
        this.setState({ showImages: !this.state.showImages });
    }
    openImage(img,imageName) {
      
        this.setState({ userImages: img,imageName, showImages: !this.state.showImages });
    
      }
    formateDate(date) {
        if(date) {
            return Dates.fromISO(date, "utc").toFormat("MM/dd/yyyy")
        }
        return null
        // return Dates.isoToDateTime(date, "YYYY-MM-DD hh:mm A");
    }
    render() {
        let self = this;
        let data = [];
        if (EM.allLoaded(self.entity)) {
            data = self.state.orders;
        }
        return (
            <div key="contents" className="page container-fluid">                
                <PageTitle title={`Orders ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`} bar={true} icon={this.props.pageIcon}></PageTitle>               
                {data.map((o,i) => {
                    return (
                        <div key={i} className="orderContainer">
                            <div className="row ml-4 mr-4 p-2 border-top border-bottom" style={{ backgroundColor: '#ecf0f1' }}>
                                <div className="col"> {self.formateDate(o.createdDate)}</div>
                                <div className="col">{o.printOrderId}</div>
                                <div className="col">$ {o.total.toFixed(2)}</div>
                                <div className="col font-weight-bold text-success">{o.shipped?"Shipped":''}</div>
                                <div className="col font-weight-bold text-success">{self.formateDate(o.shippedDateTime)}</div>
                                {
                                    
                                    (o.printQueueStatusId === 4)?
                                    (<div className="col-auto"><button className="btn btn-danger m-2" title="Requeue" style={{width:"150px"}} onClick={() => { this.handleRequeue(o.printOrderId)}}>Requeue</button></div>):
                                    (o.printQueueStatusId === 1)?<div className="col-auto">
                                    <div className="alert alert-info" style={{width: "150px", textAlign:"center"}} role="alert">Queued</div></div>:
                                    (o.printQueueStatusId === 2)?<div className="col-auto">
                                        <div className="alert alert-info" style={{width: "150px", textAlign:"center"}} role="alert">Processing</div>
                                        </div>:
                                    (<div className="col-auto"><button onClick={() => self.fulfilled(o)} className="btn btn-success pl-2 pr-2" style={{width:"150px"}}>Fulfilled</button></div>)
                                    }
                            </div>
                            <div className="userDetailContainer ml-4 mr-4 pl-5 pb-3 pt-3 border-bottom" style={{ backgroundColor: "#f5f6fa" }}>
                                <div className="row mb-1">
                                    <div className="col-1">
                                        Name:
                                    </div>
                                    <div className="col-2">
                                        {
                                            !o.user.firstName ? `${o.shippingAddress.shipToFirstName} ${o.shippingAddress.shipToLastName}`:`${o.user.firstName} ${o.user.lastName}`
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        Address:
                                    </div>
                                    <div className="col-10">
                                        {self.renderAddress(o.shippingAddress)}
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-1">
                                        Email:
                                    </div>
                                    <div className="col-3">
                                        {o.user.emailAddress}
                                    </div>
                                    <div className="col-1">
                                        Phone:
                                    </div>
                                    <div className="col-2">
                                        {o.user.phoneNumber}
                                    </div>
                                </div>
                            </div>
                            <div className="orderItemContainer ml-4 mr-4  mb-2 p-2 font-weight-bold border-bottom" style={{ backgroundColor: "#ecf0f1" }}>
                                <div className="row ml-1  mr-1 p-2" style={{ backgroundColor: "#ced6e0" }}>
                                    <div className="col">Name</div>
                                    <div className="col">Quantity</div>
                                    <div className="col"></div>
                                </div>
                                {o.printOrderLineItems.map((oli,index) => { return self.renderOrderLineItems(oli,index) })}
                            </div>
                        </div>
                    )
                })}
                <PanelModal isOpen={this.state.showImages}>
          <ModalHeader>
             {this.state.imageName}
             </ModalHeader>
          <ModalBody>
            <Form className="form-create">
              <FormGroup className="form-group userImages">
                
                    <div className="m-1"><SmartImage src={(this.state.userImages!=='') ? this.state.userImages :  ''} className='img-fluid shadow-4' style={{height:500}} alt='...' /></div>
                 
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => { self.handleViewImage() }}>Close</Button>
          </ModalFooter>
        </PanelModal>
            </div>
        )
    }
}
export default OrderFullfillment;