/* global EM */
import React, { Component } from 'react';
import CardStats from "../components/CardStats";
import _ from 'underscore';

class DashboardCount extends Component {
    constructor(props) {
        super(props);
        let user = EM.account.get();
        this.entity = EM.dashboard
        let company = _.findWhere(user.userCompanies, { defaultCompany: true });
        this.state = {
            parentRecordDetail: { id: company.companyId },
        };   
          this.entity.loadEntityByParent(this.state.parentRecordDetail);
      }
    
    getColorForValue = (value) => {
        return 'green';
    };

    render() {
        let data = {};
        if (this.entity.isLoaded()) {
          data = this.entity.get();
          if(data.totalCompanies) delete data.totalCompanies
        }
        return (
            <div className="container">  
                <h4>Events</h4>               
                <div className="row">
                    <div className="col-md-3">
                        <CardStats label={"Total Events"} value={data.totalEvents} color={this.getColorForValue(data.totalEvents)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Total Events Days"} value={data.totalEventDays} color={this.getColorForValue(data.totalEventDays)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Past Events Days"} value={data.pastEventCounts} color={this.getColorForValue(data.pastEventCounts)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Future Events Days"} navigate={this.props.history} to="/futureEvents" value={data.futureEventCounts} color={this.getColorForValue(data.futureEventCounts)}/>
                    </div>
                </div>
                <h4 className='mt-4'>User Orders</h4>               
                <div className="row">
                    <div className="col-md-3">
                        <CardStats label={"Total Orders"}  value={data.totalOrders} color={this.getColorForValue(data.totalOrders)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"No Purchases"} navigate={this.props.history} to="/noOrderUsers" value={data.noOrderUsers} color={this.getColorForValue(data.noOrderUsers)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Incomplete Cart Users"} navigate={this.props.history} to="/incompleteOrders" value={data.inCompleteCartUsers} color={this.getColorForValue(data.inCompleteCartUsers)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Complete Cart Users"} navigate={this.props.history} to="/totalCompletedOrders" value={data.completeCartUsers} color={this.getColorForValue(data.completeCartUsers)}/>
                    </div>
                </div>
                <h4 className='mt-4'>Users</h4>               
                <div className="row">
                    <div className="col-md-3">
                        <CardStats label={"Total Users"} value={data.totalUsers} color={this.getColorForValue(data.totalUsers)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Total Active Users"} value={data.totalActiveUsers} color={this.getColorForValue(data.totalActiveUsers)}/>
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Total InActive Users"} value={data.totalInActiveUsers} color={this.getColorForValue(data.totalInActiveUsers)}/>
                    </div>
                    <div className="col-md-3 mb-4">
                        <CardStats label={"Non App Users"} value={data.nonSignUpUsers} color={this.getColorForValue(data.nonSignUpUsers)}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardCount;
